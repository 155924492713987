// useVuetify.ts
import { getCurrentInstance } from 'vue'
import { Framework } from 'vuetify'

export function useVuetify (): Framework {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error('useVuetify should be called in setup().')
  }
  return instance.proxy.$vuetify
}
